import { Group, SpotLight, PointLight, AmbientLight, HemisphereLight, Color } from 'three';

export default class BasicLights extends Group {
  constructor(...args) {
    super(...args);

    const point = new PointLight(0xFFFFFF, 1, 10, 1);
    const dir = new SpotLight(0xFFFFFF, 0.8, 7, 0.8, 1, 1);
    const ambi = new AmbientLight( 0xffffff , 0.66);
    const hemi = new HemisphereLight( 0xffffff, 0x080820, 1.20 )

    dir.position.set(-20,5,-1);
    dir.target.position.set(0,0,0);

    point.position.set(-20,5,-1);
    point.intensity = 1;

    this.add(point, ambi, hemi, dir);

  }
}
