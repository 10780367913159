import { Raycaster, Vector2 } from "three";
import pauseAnimationStore from "../helpers/pauseAnimation";
import pauseHoverStore from "../helpers/pauseHover";

export default function hover(scene, camera, spherePostion) {
  const wrapperSeatHovered = document.getElementById("wrapper-seat-hovered");
  const body = document.getElementsByTagName("body")[0];
  const raycaster = new Raycaster();
  const mouse = new Vector2();

  let pauseAnimation = pauseAnimationStore.getState().pauseAnimation;
  const unsubscribe = pauseAnimationStore.subscribe((state) => {
    pauseAnimation = state.pauseAnimation;
  });

  let pauseHover = pauseHoverStore.getState().pauseHover;
  const unsubscribeHover = pauseHoverStore.subscribe((state) => {
    // console.log("État de pauseHover a changé :", pauseHover);
    pauseHover = state.pauseHover;
  });

  document.addEventListener("mousemove", onDocumentMouseMove, true);
  function onDocumentMouseMove(event) {
    event.preventDefault();
    if (pauseHover) return;
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    raycaster.params.Points.threshold = 60;
    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObjects(scene.children[0].children[0].children[0].children);
    if (intersects.length > 0) {
      const currentName = intersects[0].object.name.substring(0, 8);
      if (currentName === "Dossiers") {
        body.style.cursor = "pointer";
        // console.log("Name : ", intersects[0].object.name);
      }
      if (
        currentName === "Dossiers" &&
        intersects[0].object.userData.status === true
      ) {
        body.style.cursor = "pointer";
        wrapperSeatHovered.classList.add("active");
        const target = wrapperSeatHovered.getElementsByClassName("number");
        target[0].innerHTML = intersects[0].object.name;
      } else {
        body.style.cursor = "default";
        wrapperSeatHovered.classList.remove("active");
      }
    }
  }
}
