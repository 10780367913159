import { Group, Vector3, MeshBasicMaterial, Box3 } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
// import MODEL from './concerthall.glb';
import SIEGES from "./Land.glb";

import { fetchSeatsData } from "../../js/init";

export default class Land extends Group {
  constructor() {
    const loader = new GLTFLoader();
    super();

    this.name = "land";
    this.highlightColor = 0x38b145;
    this.highlightRed = 0xff0000;
    this.highlightMaterial = new MeshBasicMaterial({
      color: this.highlightColor,
    });
    this.highlightMaterialRed = new MeshBasicMaterial({
      color: this.highlightRed,
    });

    this.seats = (async () => {
      try {
        this.seats = await fetchSeatsData();
      } catch (error) {
        console.error("Error:", error);
      }
    })();

    this.objTheaterBox = '';

    loader.load(SIEGES, (gltf) => {
      this.add(gltf.scene);
      this.objTheaterBox = new Box3().setFromObject(gltf.scene);

      gltf.scene.traverse((child) => {
        if (child.isMesh) {
          const originalMaterial = child.material;

          if (child.name.substring(0, 8) == "Dossiers") {
            child.material.transparent = true;
            child.material.opacity = 0.8;
          }

          if (this.seats) {
            this.seats.map((seat) => {
              if (child.name === seat.name && seat.status) {
                child.material = child.material.clone();
                child.material = this.highlightMaterial;
                child.userData = {
                  name: child.userData.name,
                  status: seat.status,
                };
              }
            });
          }
        }
      });
    });
  }
  // checkCollisionWithCamera(camera) {
  //   // Créer une boîte englobante autour de la caméra
  //   if(camera){
  //     const cameraBox = new Box3(
  //       new Vector3().subVectors(camera.position, new Vector3(1, 1, 1)), // Min
  //       new Vector3().addVectors(camera.position, new Vector3(1, 1, 1))  // Max
  //     );
  //     if(this.objTheaterBox){
  //       if(!cameraBox.intersectsBox(this.objTheaterBox)){
  //         const newPosition = objTheaterBox.getCenter(new Vector3());
  //         camera.position.set(newPosition.x, newPosition.y, newPosition.z); // Déplacer la caméra au centre de l'objet théâtre
  //       }
  //     }
  //   }

  // }
}
