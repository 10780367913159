/**
 * entry.js
 *
 * This is the first file loaded. It sets up the Renderer,
 * Scene and Camera. It also starts the render loop and
 * handles window resizes.
 *
 */
//CSS
import "./public/scss/main.scss";
//THREEJS
import {
  WebGLRenderer,
  PerspectiveCamera,
  Scene,
  Vector3,
  Raycaster,
  Vector2,
  Box3,
  SphereGeometry,
  MeshBasicMaterial,
  Mesh,
  Object3D
} from "three";
import SeedScene from "./objects/Scene.js";
import filter from "./js/filter.js";
import map from "./js/map.js";
import search from "./js/search.js";
import interaction from "./js/interaction";
import cameras from "./threejs/cameras.js";
import cameraInit from "./js/camera.js";
import hover from "./js/hover.js";
import cursor from "./js/cursor.js";
import first from "./js/first.js";
import { pushDataInSeat } from "./js/init";
import { FirstPersonControls } from "three/examples/jsm/controls/FirstPersonControls.js";
import * as TWEEN from "@tweenjs/tween.js";

import Lottie from "lottie-web";

import pauseAnimationStore from "./helpers/pauseAnimation";
import pauseHoverStore from "./helpers/pauseHover.js";
const elementLottie = document.getElementById("lottie");
Lottie.loadAnimation({
  container: elementLottie, 
  renderer: 'svg',
  loop: true,
  autoplay: true,
  path: './assets/images/lottie-3.json' // the path to the animation json
});

let pauseAnimation = pauseAnimationStore.getState().pauseAnimation;
const unsubscribe = pauseAnimationStore.subscribe((state) => {
  console.log("État de pauseAnimation a changé :", pauseAnimation);
  pauseAnimation = state.pauseAnimation;
});
let pauseHover = pauseHoverStore.getState().pauseHover;
const unsubscribeHover = pauseHoverStore.subscribe((state) => {
  console.log("État de pauseHover a changé :", pauseHover);
  pauseHover = state.pauseHover;
});

const scene = new Scene();
const camera = new PerspectiveCamera();
const renderer = new WebGLRenderer({ antialias: true });
const seedScene = new SeedScene(camera);
const raycaster = new Raycaster();
const mouse = new Vector2();



// scene
scene.add(seedScene);

var sphereGeometry = new SphereGeometry(0, 16, 16);
var sphereMaterial = new MeshBasicMaterial({ color: 0xff0000 });
var sphere = new Mesh(sphereGeometry, sphereMaterial);
sphere.position.set(12, -6, 0);
scene.add(sphere);



// renderer
renderer.setPixelRatio(window.devicePixelRatio);
renderer.setClearColor(0x000000, 1);

// add sphere
var center = new Vector3();
var box = new Box3().setFromObject(scene);
box.getCenter(center);

// controls
const controlsTarget = new Object3D();
controlsTarget.position.copy(sphere.position);

const controls = '';
//Init Camera
cameraInit(scene, camera, sphere.position, controls);


renderer.domElement.addEventListener("mouseup", function (event) {
  //Position MOUSE
  var rect = renderer.domElement.getBoundingClientRect();
  mouse.x = ((event.clientX - rect.left) / (rect.width - rect.left)) * 2 - 1;
  mouse.y = -((event.clientY - rect.top) / (rect.bottom - rect.top)) * 2 + 1;

  //Init raycaster
  raycaster.setFromCamera(mouse, camera);
  const intersects = raycaster.intersectObjects(scene.children[0].children[0].children[0].children);
  if (intersects.length === 0) return;
  const currentMarker = intersects[0].object;
  const currentName = intersects[0].object.name.substring(0, 8);
  // console.log(currentMarker);
  if (
    currentName == "Dossiers" &&
    intersects[0].object.userData.status === true
  ) {

    var worldPosition = new Vector3();
    currentMarker.getWorldPosition(worldPosition);

    //Camera position & translation
    var cameraHeight = .9;
    var cameraPosition = new Vector3(
      worldPosition.x,
      worldPosition.y + cameraHeight,
      worldPosition.z
    );
    var orbitPosition = new Vector3(
      worldPosition.x,
      worldPosition.y,
      worldPosition.z
    );
    var transitionDuration = process.env.DEFAULT_ANIMATION;
    const firstPersonControls = new FirstPersonControls(
      camera,
      renderer.domElement
    );

    // Activer les contrôles de la première personne
    firstPersonControls.lookSpeed = 0.05; // Ajuster la vitesse de regard

    firstPersonControls.enabled = false;

    var cameraTween = new TWEEN.Tween(camera.position)
      .to(cameraPosition, transitionDuration)
      .easing(TWEEN.Easing.Quadratic.Out);
    cameraTween.start();
    cameraTween.onStart(function () {
      // controls.enabled = true;
      firstPersonControls.enabled = false;
      camera.lookAt(sphere.position);

      pauseAnimationStore.getState().setPauseAnimation(true);
      pauseAnimation = true;

      pauseHoverStore.getState().setPauseHover(true);
      pauseHover = true;
    });
    cameraTween.onUpdate(function () {
      firstPersonControls.enabled = false;
      camera.lookAt(sphere.position);
      // controls.enabled = true;
      camera.lookAt(sphere.position);
      // controlsTarget.position.copy(sphere.position);
      // controls.update();
    });
    cameraTween.onComplete(function () {

      pauseAnimationStore.getState().setPauseAnimation(true);
      pauseAnimation = true;

      pauseHoverStore.getState().setPauseHover(false);
      pauseHover = false;

      // // Écouter l'événement mousedown (clic gauche enfoncé)
      // renderer.domElement.addEventListener("mousedown", function (event) {
      //   if (event.button === 0) {
      //     isMouseLeftPressed = true;
      //   }
      // });

      // // Écouter l'événement mouseup (bouton de la souris relâché)
      // renderer.domElement.addEventListener("mouseup", function (event) {
      //   if (event.button === 0) {
      //     isMouseLeftPressed = false;
      //   }
      // });

      // // controls.enabled = false;
      // firstPersonControls.enabled = false;
      // firstPersonControls.lookAt(sphere.position);

      // // const wrapperSetup = document.querySelector("#wrapper-setup");
      // // wrapperSetup.classList.add("active");

      // let previousTime = performance.now();
      // function animateFirstPerson() {
      //   const currentTime = performance.now();
      //   const deltaTime = (currentTime - previousTime) / 1000;

      //   firstPersonControls.canMoveForward = false; // Déplacer vers l'avant
      //   firstPersonControls.canMoveBackward = false; // Déplacer vers l'arrière
      //   firstPersonControls.moveForward = false; // Déplacer vers l'avant
      //   firstPersonControls.moveBackward = false; // Déplacer vers l'arrière

      //   // Mettre à jour les contrôles de la première personne
      //   firstPersonControls.update(deltaTime);
      //   previousTime = currentTime;

      //   // Appel récursif pour continuer l'animation
      //   requestAnimationFrame(animateFirstPerson);
      // }
      // // Commencer l'animation de première personne
      // animateFirstPerson();
    });
  }
  if (currentName == "camera1") {
    //Tween camera to position camera1
    const camera1 = currentMarker.position;
    var cameraTween = new TWEEN.Tween(camera.position)
      .to(camera1, transitionDuration)
      .easing(TWEEN.Easing.Quadratic.Out)
      .onUpdate(function () {
        camera.lookAt(sphere.position);
      });
    cameraTween.start();
  }

  //Interaction Seat
  const seatBloc = document.querySelector("#wrapper-seat");
  const rightBar = document.querySelector("#rightBar");
  const wrapperSeatHovered = document.getElementById("wrapper-seat-hovered");
  if (
    currentName == "Dossiers" &&
    intersects[0].object.userData.status === true
  ) {
    //Non réservé
    seatBloc.classList.add("active");
    pushDataInSeat(intersects[0].object.userData.name);
    rightBar.classList.remove("active");

    pauseAnimationStore.getState().setPauseAnimation(true);
    pauseAnimation = true;

    wrapperSeatHovered.classList.remove("active");
    
    // console.log('HERE 1')
  } else {
    // rightBar.classList.add('active');
    // seatBloc.classList.remove("active");
    // pushDataInSeat(intersects[0].object.id);
    // seatBloc.classList.remove("active");
    // console.log('HERE 2')
  }
});


// render loop
const onAnimationFrameHandler = () => {
  renderer.render(scene, camera);
  TWEEN.update();
  window.requestAnimationFrame(onAnimationFrameHandler);
};
window.requestAnimationFrame(onAnimationFrameHandler);

// resize
const windowResizeHanlder = () => {
  const { innerHeight, innerWidth } = window;
  renderer.setSize(innerWidth, innerHeight);
  camera.aspect = innerWidth / innerHeight;
  camera.updateProjectionMatrix();
};
windowResizeHanlder();
window.addEventListener("resize", windowResizeHanlder);

// dom
interaction(scene, camera, sphere.position);
hover(scene, camera, sphere.position);
filter(renderer, scene, camera);
map(scene, camera, sphere.position,controls);
search(scene);
// joystick(camera,renderer,scene,sphere.position);
cameras(camera, scene, sphere.position);
first(camera, renderer);
cursor();
document.body.style.margin = 0;
document.body.appendChild(renderer.domElement);

document.addEventListener("click", function (event) {

  const firstPersonControls = new FirstPersonControls(
    camera,
    renderer.domElement
  );

  const target = event.target;
  const action = target.getAttribute("data-action");

  if (action === "closeSeat") {
    const seatBloc = document.querySelector("#wrapper-seat");
    const active = seatBloc.classList.contains("active");
    const rightBar = document.querySelector("#rightBar");
    // rightBar.classList.add("active");


    const position = {
      x: camera.position.x,
      y: camera.position.y,
      z: camera.position.z,
    };

    const tween = new TWEEN.Tween(position).to(
      JSON.parse(process.env.DEFAULT_POSITION),
      1000
    );
    tween.onStart(() => {
      pauseAnimationStore.getState().setPauseAnimation(true);
      pauseAnimation = true;

      pauseHoverStore.getState().setPauseHover(true);
      pauseHover = true;
    })
    tween.onUpdate(() => {
      camera.lookAt(sphere.position);
      camera.position.set(position.x, position.y, position.z);
    });
    tween.onComplete(() => {
      pauseAnimationStore.getState().setPauseAnimation(false);
      pauseAnimation = false;
      pauseHoverStore.getState().setPauseHover(false);
      pauseHover = false;
      // pauseAnimation = false;
    });
    tween.start();

    return active
      ? seatBloc.classList.remove("active")
      : seatBloc.classList.add("active");
  }
});
