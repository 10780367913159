import { Object3D, Vector3, Euler } from "three";
import * as TWEEN from "@tweenjs/tween.js";

import pauseAnimationStore from "../helpers/pauseAnimation";
import pauseHoverStore from "../helpers/pauseHover";

export default function camera(scene, camera, spherePosition, controls) {
  // Définition de la position initiale de la caméra et du point qu'elle regarde
  const cameraPosition = JSON.parse(process.env.DEFAULT_POSITION);
  const lookAtPositionInitial = new Vector3(12, -10, 0);

  let lastMouseY = 0;
  let isMouseDown = false;
  let cameraSpeed = 0.02;
  let targetPositionX = camera.position.x;

  camera.position.add(cameraPosition);
  camera.lookAt(lookAtPositionInitial);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  // Éléments de l'interface utilisateur
  const buttonWelcome = document.querySelector("#button-welcome");
  const wrapperWelcome = document.querySelector(".wrapper-bloc-welcome");
  const rightBarMenu = document.querySelector("#rightBar");
  const body = document.querySelector("body");

  let pauseAnimation = pauseAnimationStore.getState().pauseAnimation;
  const unsubscribe = pauseAnimationStore.subscribe((state) => {
    // console.log("État de pauseAnimation a changé dans :", pauseAnimation);
    pauseAnimation = state.pauseAnimation;
  });

  let pauseHover = pauseHoverStore.getState().pauseHover;
  const unsubscribeHover = pauseHoverStore.subscribe((state) => {
    // console.log("État de pauseHover a changé :", pauseHover);
    pauseHover = state.pauseHover;
  });

  buttonWelcome.onclick = () => {
    const isActive = wrapperWelcome.classList.contains("active");
    const topBar = document.querySelector("#topBar");
    topBar.classList.add("active");
    if (isActive) {
      wrapperWelcome.classList.remove("active");
    } else {
      isMobile
        ? rightBarMenu.classList.remove("active")
        : rightBarMenu.classList.add("active");
      wrapperWelcome.classList.add("active");
      body.classList.replace("not-loaded", "loaded");
    }

    const newPosition = {
      x: cameraPosition.x,
      y: cameraPosition.y - 1,
      z: cameraPosition.z,
    };

    const entryMessage = document.querySelector("#entry-message");
    entryMessage.classList.add("active");

    // Animation de la position de la caméra avec TWEEN
    new TWEEN.Tween(camera.position)
      .to(newPosition, process.env.DEFAULT_ANIMATION)
      .onComplete(() => {
        pauseAnimationStore.getState().setPauseAnimation(false);
        pauseAnimation = false;

        pauseHoverStore.getState().setPauseHover(false);
        pauseHover = false;
      })
      .start();
  };

  // Gestion des événements clavier
  window.addEventListener("keydown", handleKeyboardEvent, false);
  function handleKeyboardEvent(event) {
    if (event.key === "p" || event.key === "P") {
      // console.log(
      //   `Position actuelle de la caméra : x:${camera.position.x}, y:${camera.position.y}, z:${camera.position.z}`
      // );
    } else if (event.key === "m" || event.key === "M") {
      var direction = new Vector3();
      camera.getWorldDirection(direction);
      var distance = 100;
      var calculatedLookAtPosition = direction
        .multiplyScalar(distance)
        .add(camera.position);
      // console.log(
      //   `La caméra regarde vers : x:${calculatedLookAtPosition.x}, y:${calculatedLookAtPosition.y}, z:${calculatedLookAtPosition.z}`
      // );
    }
  }

  let lastMouseX = 0;
  let mouseSensitivity = 0.001;
  let mouseSensitivityY = 0.001;
  let euler = new Euler(0, 0, 0, "YXZ");

  document.addEventListener("mousedown", function (event) {
    isMouseDown = true;
    lastMouseY = event.clientY;
    lastMouseX = event.clientX;

    pauseHoverStore.getState().setPauseHover(true);
    pauseHover = true;

    const entryMessage = document.querySelector("#entry-message");
    entryMessage.classList.remove("active");
  });

  document.addEventListener("mouseup", function () {
    isMouseDown = false;
    pauseHoverStore.getState().setPauseHover(false);
    pauseHover = false;
  });

  let currentTween;

  document.addEventListener("mousemove", (event) => {
    if (!isMouseDown || pauseAnimation) return;

    if (currentTween) currentTween.stop(); // Arrête l'animation en cours

    // let deltaY = event.clientY - lastMouseY;
    // let newTargetPositionX = targetPositionX + deltaY * cameraSpeed;
    // newTargetPositionX = Math.max(-15.5, Math.min(15.5, newTargetPositionX));
    // targetPositionX = newTargetPositionX;

    // // Crée une nouvelle animation pour la position X de la caméra
    // currentTween = new TWEEN.Tween(camera.position)
    //   .to({ x: targetPositionX }, 0)
    //   // .easing(TWEEN.Easing.Quadratic.Out)
    //   .start();

    let deltaY = event.clientY - lastMouseY;

    let newTargetPositionX = targetPositionX + deltaY * cameraSpeed;

    newTargetPositionX = Math.max(-15.5, Math.min(15.5, newTargetPositionX));

    targetPositionX = newTargetPositionX;
    camera.position.x += (targetPositionX - camera.position.x) * 0.2;

    let deltaX = event.clientX - lastMouseX;
    euler.setFromQuaternion(camera.quaternion);
    euler.y -= deltaX * mouseSensitivityY;
    euler.x = Math.max(Math.PI / -2, Math.min(Math.PI / 2, euler.x));
    camera.quaternion.setFromEuler(euler);

    lastMouseX = event.clientX;
    lastMouseY = event.clientY;
  });

  /**
   *
   * MOBILE MOVE !!
   */

  let isTouching = false;
  let touchSensitivityMultiplier = 2.5;

  document.addEventListener("touchstart", function (event) {
    isTouching = true;
    lastMouseY = event.touches[0].clientY;
    lastMouseX = event.touches[0].clientX;
    const entryMessage = document.querySelector("#entry-message");
    entryMessage.classList.remove("active");

    pauseHoverStore.getState().setPauseHover(true);
    pauseHover = true;
  });

  // Adaptation pour touchend
  document.addEventListener("touchend", function () {
    isTouching = false;
    pauseHoverStore.getState().setPauseHover(false);
    pauseHover = false;
  });
  let cameraSpeedMobile = 0.05;
  // Adaptation pour touchmove
  document.addEventListener(
    "touchmove",
    (event) => {
      if (!isTouching || pauseAnimation) return;
      if (currentTween) currentTween.stop(); // Arrête l'animation en cours

      let deltaY = event.touches[0].clientY - lastMouseY;

      let newTargetPositionX = targetPositionX + deltaY * cameraSpeedMobile;

      newTargetPositionX = Math.max(-15.5, Math.min(15.5, newTargetPositionX));

      targetPositionX = newTargetPositionX;
      camera.position.x += (targetPositionX - camera.position.x) * 0.2;

      let deltaX =
        (event.touches[0].clientX - lastMouseX) * touchSensitivityMultiplier;
      euler.setFromQuaternion(camera.quaternion);
      euler.y -= deltaX * mouseSensitivityY;
      euler.x = Math.max(Math.PI / -2, Math.min(Math.PI / 2, euler.x));
      camera.quaternion.setFromEuler(euler);

      lastMouseX = event.touches[0].clientX;
      lastMouseY = event.touches[0].clientY;

      event.preventDefault();
    },
    { passive: false }
  );
}
