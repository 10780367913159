import {
  SphereGeometry,
  MeshBasicMaterial,
  Mesh,
} from "three";
export default function cameras(camera,scene,spherePostion) {
  var sphereGeometry = new SphereGeometry(1, 1, 1);
  var sphereMaterial = new MeshBasicMaterial({ color: 0xff0000 });
  var sphere = new Mesh(sphereGeometry, sphereMaterial);
  sphere.position.set(17.414, 3, -9.591);
  sphere.name = 'camera1'
  // scene.add(sphere);
}
