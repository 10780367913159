import { Group } from 'three';
import Land from './Land/Land.js';
import BasicLights from './Lights.js';

export default class SeedScene extends Group {
  constructor(camera) {
    super();

    this.camera = camera; 
    this.land = new Land();
    const lights = new BasicLights();

    this.add(this.land, lights);
  }

  update(timeStamp) {
    this.rotation.y = timeStamp / 10000;
  }

  collision(){
    // console.log(this.land);
    if (this.camera) {
      // this.land.checkCollisionWithCamera(this.camera);
    }
  }
}