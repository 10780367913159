import * as TWEEN from "@tweenjs/tween.js";
export default function targetZone(scene,camera,spherePosition,controls){
  const zoneClick = document.querySelector("#target-zone");
  const wrapperMap = document.querySelector("#wrapper-map");
  zoneClick.addEventListener("click", function (event) {
    // console.log(camera)
    const cameraSelected = event.target.dataset.camera;
    const cameraLookAt = event.target.dataset.lookat;
    const active = wrapperMap.classList.contains('active')
    if(cameraSelected){

      const position = { x: camera.position.x, y: camera.position.y, z: camera.position.z };
      const lookAt = controls.target.clone();


      const tweenCamera = new TWEEN.Tween(position).to({
        x: cameraSelected.split(',')[0],
        y: cameraSelected.split(',')[1],
        z: cameraSelected.split(',')[2]
      }, process.env.DEFAULT_ANIMATION);
      tweenCamera.onUpdate(() => {
        camera.position.set(position.x, position.y, position.z); 
      });
  
      const tweenLookAt = new TWEEN.Tween(lookAt).to({
          x:cameraLookAt.split(',')[0],
          y:cameraLookAt.split(',')[1],
          z:cameraLookAt.split(',')[2]
        }, process.env.DEFAULT_ANIMATION);
      tweenLookAt.onUpdate(() => {
        controls.target.set(lookAt.x, lookAt.y, lookAt.z);
        controls.update();
      });

      tweenLookAt.start();
      tweenCamera.start();



      
      
      (active) ? wrapperMap.classList.remove('active') : wrapperMap.classList.add('active')
    }
  });
}