import { MeshStandardMaterial } from "three";
import seats from "../../public/datas/seats.json";

export default function displayFilter(renderer, scene, camera) {
  const buttonFilter = document.querySelectorAll(".button-filter");
  const buttonMenuFilter = document.querySelector("#filterButton");
  const filterActifs = [];
  const seatsScene = scene.children[0].children[0];
  const highlightRed = 0x38b145;
  const highlightMaterialRed = new MeshStandardMaterial({
    color: highlightRed,
    // emissive: 0x000000,
    // roughness: 0,
    // metalness: 0.2,
  });
  
  function updateCheckedCount() {
    const checkedCount = Array.from(buttonFilter).filter(checkbox => checkbox.checked).length;
    (checkedCount === 0) ? buttonMenuFilter.innerHTML = 'Filtrer' : buttonMenuFilter.innerHTML = 'Filtrer <span><sup>('+checkedCount+')</sup></span>';
  }

  buttonFilter.forEach((button) => {
    
    button.addEventListener("click", (event) => {
      updateCheckedCount();
      const originalMaterial =
        seatsScene.children[0].children[0].children[0].material;

      const value = event.currentTarget.value;
      const seatsObject = seatsScene.children[0].children;

      //init filterActifs
      const index = filterActifs.indexOf(value);
      index === -1 ? filterActifs.push(value) : filterActifs.splice(index, 1);

      const similarChildren = seats.seats.map((item1) =>
        seatsObject.flatMap((item2) =>
          item2.children.filter(
            (child) =>
              child.name === item1.name && filterActifs.includes(item1.cat)
          )
        )
      );
      const seatsFiltered = similarChildren.flat();

      const notSimilarChildren = seats.seats.map((item1) =>
        seatsObject.flatMap((item2) =>
          item2.children.filter(
            (child) =>
              child.name === item1.name && !filterActifs.includes(item1.cat)
          )
        )
      );
      const seatsNotFiltered = notSimilarChildren.flat();

      seatsFiltered.forEach((seat) => {
        seat.material = seat.material.clone();
        seat.material = highlightMaterialRed;
      });
      seatsNotFiltered.forEach((seat) => {
        seat.material = seat.material.clone();
        seat.material = originalMaterial;
      });

      if (index === 0) {
        const similarSeatsChildren = seats.seats
          .map((item1) =>
            seatsObject.flatMap((item2) =>
              item2.children.filter(
                (child) => child.name === item1.name && item1.status
              )
            )
          )
          .filter((item) => item.length > 0);
        similarSeatsChildren.forEach((seat) => {
          seat[0].material = seat[0].material.clone();
          seat[0].material = highlightMaterialRed;
        });
      }
    });
  });
  updateCheckedCount();
}
