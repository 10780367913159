import handlebars from "handlebars";

export async function fetchSeatsData() {
  try {
    const response = await fetch("./public/datas/seats.json");
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const seatsData = await response.json();
    return seatsData.seats;
  } catch (error) {
    console.error("Error fetching seats data:", error);
    return null;
  }
}

async function searchSeatInData(name) {
  try {
    const response = await fetch("./public/datas/seats.json");
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const seatsData = await response.json();

    const foundSeat = seatsData.seats.find((seat) => seat.name === name);
    return foundSeat || null;
  } catch (error) {
    console.error("Error fetching seats data:", error);
    return null;
  }
}

export async function pushDataInSeat(data) {
  try {
    const seatData = await searchSeatInData(data);
    // console.log(data);
    const templatePath = "./partials/seat/seat.hbs";
    fetch(templatePath)
      .then((response) => response.text())
      .then((template) => {
        const compiledTemplate = handlebars.compile(template);
        const renderedHtml = compiledTemplate({ seatData });
        const appContainer = document.getElementById("wrapper-seat");
        appContainer.innerHTML = renderedHtml;     
      });

  } catch (error) {
    console.error("Error:", error);
  }
}
