import { FirstPersonControls } from "three/examples/jsm/controls/FirstPersonControls.js";
import pauseHoverStore from "../helpers/pauseHover.js";

export default function firstView(camera, renderer) {


  let pauseHover = pauseHoverStore.getState().pauseHover;
  const unsubscribeHover = pauseHoverStore.subscribe((state) => {
    pauseHover = state.pauseHover;
  });

  let activeFirstPerson = true;
  document.addEventListener("click", (event) => {
    // event.preventDefault();
    // if (event.target.matches(".button-360deg") && activeFirstPerson) {
    //   pauseHoverStore.getState().setPauseHover(true);
    //   pauseHover = true;
    //   event.target.classList.add("active");
    //   const firstPersonControls = new FirstPersonControls(
    //     camera,
    //     renderer.domElement
    //   );

    //   firstPersonControls.lookSpeed = 0.05;
    //   firstPersonControls.enabled = true;

    //   let previousTime = performance.now();
    //   function animateFirstPerson() {
    //     const currentTime = performance.now();
    //     const deltaTime = (currentTime - previousTime) / 1000;
    //     firstPersonControls.update(deltaTime);
    //     previousTime = currentTime;
    //     requestAnimationFrame(animateFirstPerson);
    //   }
    //   animateFirstPerson();
    // }
  });
}
