
import * as TWEEN from "@tweenjs/tween.js";
export default function interaction(scene,camera,spherePostion){
  //Active element topbar


  const rightBar = document.querySelector("#filterButton")
  const rightBarMenu = document.querySelector("#rightBar")
  rightBar.onclick = () => {
    const active = rightBarMenu.classList.contains('active')
    return (active) ? rightBarMenu.classList.remove('active') : rightBarMenu.classList.add('active')
  };

  const closeRightBar = document.querySelector(".close-right-bar")
  closeRightBar.onclick = () => {
    const active = rightBarMenu.classList.contains('active')
    return (active) ? rightBarMenu.classList.remove('active') : rightBarMenu.classList.add('active')
  };


  const buttonMap = document.querySelector(".button-map")
  const wrapperMap = document.querySelector("#wrapper-map")
  buttonMap.onclick = () => {
    const active = wrapperMap.classList.contains('active')
    return (active) ? wrapperMap.classList.remove('active') : wrapperMap.classList.add('active')
  }
  const closeMap = document.querySelector(".close-map");
  closeMap.onclick = () => {
    const active = wrapperMap.classList.contains('active')
    return (active) ? wrapperMap.classList.remove('active') : wrapperMap.classList.add('active')
  }

  const buttonEntryMessage = document.querySelector(".entry-message__text")
  const entryMessage = document.querySelector("#entry-message")
  buttonEntryMessage.onclick = () => {
    const active = entryMessage.classList.contains('active')
    return (active) ? entryMessage.classList.remove('active') : entryMessage.classList.add('active')
  }

}


